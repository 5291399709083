var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mx-auto md:px-0" }, [
    _c("div", { staticClass: "w-full px-4 inline-block" }, [
      _c("h1", { staticClass: "text-2xl font-normal pt-2" }, [
        _vm._v(_vm._s(_vm.$t("components.pulldownModal.help.dictionary"))),
      ]),
    ]),
    _c("div", { staticClass: "w-full px-4 text-justify mb-8" }, [
      _c("span", { staticClass: "text-sm" }, [
        _vm._v(_vm._s(_vm.$t("pages.commons.dictionary.description"))),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass:
          "w-full px-4 grid grid-cols-1 grid-flow-row auto-rows-auto md:grid-cols-2 xl:gap-12 lg:gap-6 gap-3",
      },
      _vm._l(_vm.genres, function (genre, index) {
        return _c("div", { key: "genre-" + index, staticClass: "col-span-1" }, [
          _c("p", { staticClass: "pb-2" }, [_vm._v(_vm._s(genre.name))]),
          _c(
            "div",
            { staticClass: "ml-4" },
            [
              _vm.words[index] && _vm.words[index].length
                ? _vm._l(_vm.words[index], function (item, key) {
                    return _c(
                      "FlAccordion",
                      {
                        key: "word-" + key,
                        staticClass: "mb-2",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [_c("span", [_vm._v(_vm._s(item.word))])]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        [
                          _c("div", {
                            staticClass: "text-justify",
                            domProps: { innerHTML: _vm._s(item.description) },
                          }),
                        ],
                      ],
                      2
                    )
                  })
                : _vm._e(),
            ],
            2
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }