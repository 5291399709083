var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full border border-gray-200 rounded overflow-hidden" },
    [
      _c(
        "div",
        {
          staticClass: "w-full p-2 bg-gray-50",
          class: _vm.enableTitleBarToggle ? "cursor-pointer" : "",
          on: {
            click: function ($event) {
              _vm.enableTitleBarToggle ? _vm.toggleState(!_vm.isOpen) : null
            },
          },
        },
        [
          _vm._t(
            "header",
            function () {
              return [
                _c("span", { staticClass: "font-bold" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
              ]
            },
            { toggleState: _vm.toggleState }
          ),
          _c(
            "span",
            {
              staticClass: "h-6 w-6 float-right",
              class: _vm.enableRightButtonToggle ? "cursor-pointer" : "",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.enableRightButtonToggle
                    ? _vm.toggleState(!_vm.isOpen)
                    : null
                },
              },
            },
            [
              _c(
                "svg",
                {
                  staticClass:
                    "h-6 w-6 inline transition transform duration-200",
                  class: _vm.isOpen ? "rotate-180" : "",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    fill: "none",
                    viewBox: "0 0 24 24",
                    stroke: "currentColor",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      d: "M19 9l-7 7-7-7",
                    },
                  }),
                ]
              ),
            ]
          ),
        ],
        2
      ),
      _c(
        "transition",
        {
          attrs: { name: "accordion" },
          on: {
            "before-enter": _vm.beforeStart,
            "after-enter": _vm.afterEnded,
            "before-leave": _vm.beforeCloseStart,
            "after-leave": _vm.afterEnded,
          },
        },
        [
          _vm.isOpen
            ? _c(
                "div",
                { ref: "content", staticClass: "w-full overflow-hidden" },
                [_c("div", { staticClass: "p-2" }, [_vm._t("default")], 2)]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }